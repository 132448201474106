import React, { useRef, useState } from 'react'
import "./Hamburger.css"
import { Link } from "gatsby"


const HamburgerMenu = () => {
    const [navbar, setnavbar] = useState("navbarhide");
    const myButton = useRef();

    function changeClass() {
        myButton.current.classList.toggle("menu--active");
        if (navbar === "navbarhide") {
            setnavbar("navbarshow")
          } else {
            setnavbar("navbarhide")
          }
    }

    return (
        <>
        <button ref={myButton} onClick={changeClass} className="menu menu--squeeze">
            <span className="menu__inner"></span>
        </button>
        <div className={`${navbar} navbar`}>
        <Link to="/kurumsal">KURUMSAL</Link>
        <Link to="/uygulamalar">UYGULAMALAR</Link>
        <Link to="/iletisim">İLETİŞİM</Link>
      </div>
        </>
    )
}

export default HamburgerMenu
