import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
      <meta name="yandex-verification" content="2806898d51b56497" />
      <script src="https://kit.fontawesome.com/2e57178062.js" crossorigin="anonymous"></script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      <main>
        {children}
      </main>
      <footer>
        <div className="com6">
          <p id="copyright">©2021 BİRÜÇEM. ALL RIGHTS RESERVED.</p>
        </div>
      </footer>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
