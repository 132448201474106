import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./header.css"
import HamburgerMenu from "./HamburgerMenu"
import { StaticImage } from "gatsby-plugin-image"


const Header = ({ siteTitle }) => {
  const [color, setcolor] = useState("");

  useEffect(() => {
    const url = document.location.pathname;
    window.addEventListener('scroll', headColorChange);
    if (url !== "/") {
      setcolor("headcolor");
    };
    function headColorChange() {
      const url = document.location.pathname;
      let dikeykonum = window.scrollY;
      if (url !== "/") {
        setcolor("headcolor");
        window.removeEventListener('scroll', headColorChange);
      } else {
        if (dikeykonum >= 10) {
          setcolor("headcolor");
        } else {
          setcolor("")
        }
      };
    };
    return
  }, []);

  return (
    <header className={color}>
      <Link className="logo" to="/">
        <StaticImage
          src="../images/logo.png"
          height={70}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="A Gatsby astronaut"
          placeholder={"blurred"}
        />
        <div className="logotext">
          {/* <h1></h1>
          <p></p> */}
        </div>
      </Link>
      <div >
        <Link className="logoyani" to="/kurumsal">KURUMSAL</Link>
        <Link className="logoyani" to="/uygulamalar">UYGULAMALAR</Link>
        <Link className="logoyani" to="/iletisim">İLETİŞİM</Link>
      </div>

      <div className="navbaricon">
        <HamburgerMenu />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
